const sx = {
    button: {
        fontWeight: "bold",
        padding: "1vmin",
        margin: "1vmin",
        borderRadius: '2vmin',
        backgroundColor: '#eeffee',
    },

    textbox: {
        padding: "1vmin",
        margin: "2vmin",
        borderRadius: '2vmin',
        minWidth: "calc(60px + 10vmin)",
    },
    appMain: {
        backgroundColor: '#aaaaaa',
        display: 'flex',
        flexWrap: 'wrap',
    },
    timeSection: {
        display: 'block',
        position: 'relative',
        float: 'right',
        width: '20vmin',
        height: '20vmin',
        backgroundColor: '#ccaaff',
        boxShadow: '1vmin',
        padding: '1vmin',
        margin: '1vmin',
        borderRadius: '2vmin',
        textAlign: 'center',
    },
    todoSection: {
        display: 'block',
        position: 'relative',
        width: 'calc(180px + 20vmin)',
        backgroundColor: '#ccffaa',
        boxShadow: '1vmin',
        padding: '1vmin',
        margin: '1vmin',
        borderRadius: '2vmin',
        textAlign: 'center',
        minHeight: '90vh',
    },
    appHeader: {
        backgroundColor: '#333333',
        minHeight: '7vmin',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 'calc(10px + 2vmin)',
        color: 'white',
    },
    completeItem: {
        padding: '1vmin',
        margin: '1vmin',
        borderRadius: "2vmin",
        color: '#aaaaaa',
        backgroundColor: '#eeeeee',
        opacity: '.5',
        transition: '1s, opacity 1s',
    },
    todoItem: {
        borderRadius: "2vmin",
        padding: '1vmin',
        margin: '1vmin',
        color: '#222222',
        backgroundColor: '#ffff99',
        transition: '.2s, opacity 1s, height 1s',
        opacity: '1',
    },
    stickyNote: {
        display: 'inline-block',
        font: 'Helvetica',
        fontSize: '12pt',
        width: 'calc(180px + 20vmin)',
        minHeight: '30vh',
        borderRadius: "2vmin",
        padding: '1vmin',
        margin: '1vmin',
        backgroundColor: '#ffccaa',
        transition: '.2s, opacity 1s, height 1s',
        opacity: '1',
    },
    noteText: {
        display: 'inline-block',
        font: 'Helvetica',
        fontSize: '12pt',
        width: 'calc(180px + 20vmin)',
        font: 'Helvetica',
        fontSize: '12pt',
        minHeight: 'calc(120px + 20vmin)',
        borderRadius: "2vmin",
        padding: '1vmin',
        margin: '1vmin',
        backgroundColor: '#ffccaa',
        transition: '.2s, opacity 1s, height 1s',
        opacity: '1',
    },
    noteSection: {
        display: 'block',
        font: 'Helvetica',
        fontSize: '12pt',
        float: 'right',
        width: 'calc(180px + 25vmin)',
        minHeight: '30vh',
        borderRadius: "2vmin",
        padding: '1vmin',
        transition: '.2s, opacity 1s, height 1s',
        opacity: '1',
    },
    stickyNote: {
        display: 'inline-block',
        font: 'Helvetica',
        fontSize: '12pt',
        width: 'calc(180px + 20vmin)',
        minHeight: 'calc(120px + 20vmin)',
        font: 'Helvetica',
        fontSize: '12pt',
        borderRadius: "2vmin",
        padding: '1vmin',
        margin: '1vmin',
        backgroundColor: '#ffccaa',
        transition: '.2s, opacity 1s, height 1s',
        opacity: '1',
    },
    deleteButton: {
        float: 'right',
        height: '4vmin',
        padding: '1vmin',
        margin: '1vmin',
        borderRadius: "1vmin",
        backgroundColor: '#ffccaa',
    }
}

export default sx